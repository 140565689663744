import {Fragment} from 'react';
import {TextField} from '@mui/material';
import './DelegateOnboarding.css';

const DelegateOnboarding = () => {
  return (
    <Fragment>
      <div className="delegate-onboarding-wrapper">
        <h1>TitleBlox</h1>
        <h3>Almost there, let's elect delegates</h3>
        <DelegateForm title={'1. Details of Head of the Department'} />
        <DelegateForm title={'2. Details of one Elected Department'} />
        <DelegateForm title={'3. Details of first Department Witness'} />
        <DelegateForm title={'4. Details of second Department Witness'} />
        <DelegateForm title={'5. Details of third Department Witness'} />
        <div className="delegate-buttons">
          <Button>Send Requests</Button>
          <Button>Add More Delegates</Button>
        </div>
      </div>
    </Fragment>
  );
};

const DelegateForm = ({title}) => {
  return (
    <Fragment>
      <h4>{title}</h4>
      <form action="">
        <div className="form-input">
          <label htmlFor="head-name" className="delegate-label">Name:</label>
          <TextField
            type="text"
            id="head-name"
            label="Head Of Department"
            variant="outlined"
            className="name-input delegate-input"
          />
        </div>
        <div className="form-input">
          <label htmlFor="head-mail" className="delegate-label">
            Email ID:
          </label>
          <TextField
            type="email"
            variant="outlined"
            id="head-mail"
            label="Enter your Email ID"
            className="mail-input  delegate-input"
          />
        </div>
        <div className="form-input">
          <label htmlFor="head-number" className="delegate-label">
            Number:
          </label>
          <TextField
            type="number"
            variant="outlined"
            id="head-number"
            label="Enter Phone Number"
            className="phone-input  delegate-input"
          />
        </div>
      </form>
    </Fragment>
  );
};

const Button = ({children}) => {
  return (
    <Fragment>
      <button className="delegate-button">{children}</button>
    </Fragment>
  );
};
export default DelegateOnboarding;
