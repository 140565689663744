import React, {useState} from 'react';
import Logo from '../../assets/logo.png';
import Carousel from '../Carousel/Carousel';
import './Hero.css';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import {Checkbox, TextField, FormControlLabel} from '@mui/material';
import Cross from '../../assets/cross_icon.svg';
import {useNavigate} from 'react-router-dom';
import tblox from '../../assets/tblox.png';
import axios from 'axios';

{
  /*This Code is for Hero Component. 
Inside Wrapper it is divided into two parts: Left Part and Right Part
Right Part Contains the Carousel of DMVs
Left Part is further divided into three parts: Logo,Text and Blocks
Blocks is further divided into two different blocks: Block-1 and Block-2
Block-1 is linked to LoginDMVModal which is further linked to DMV Registration Page
Block-2 is linked to LoginModal which further opens the User/Car Dealer's Part of the App.*/
}
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  height: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const style2 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
function InstallApp({userData}) {
  const handleSubmit = async e => {
    e.preventDefault ();
    try {
      const response = await axios.post (
        `http://54.235.239.236:4000/dmv/login`,
        userData
      );
    } catch (error) {
      console.error ('Error:', error);
    }
  };
  const [open, setOpen] = React.useState (false);
  const handleOpen = () => {
    setOpen (true);
  };
  const handleClose = () => {
    setOpen (false);
  };
  return (
    <React.Fragment>
      <Button id="but5" variant="contained" onClick={handleOpen}>
        Login
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{...style2}} id="bxxxx">
          <div className="installappWrapper">
            <h3>Install App</h3>
            <div className="Nameofproject">
              <img src={tblox} id="tblox" alt="tblox" />
              <h2>TitleBlox<br />app.titleblox.com</h2>

            </div>
            <div className="buttonssss">
              <Button variant="text" className="butttons">Cancel</Button>
              <Button variant="text" className="butttons">Install</Button>
            </div>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
}
function LoginModal () {
  const [open, setOpen] = React.useState (false);
  const handleOpen = () => {
    setOpen (true);
  };
  const handleClose = () => {
    setOpen (false);
  };
  const [userData, setUserData] = useState ({
    email: '',
    password: '',
  });
  const handleChange = e => {
    setUserData ({...userData, [e.target.name]: e.target.value});
    console.log (userData);
  };

  return (
    <React.Fragment>
      <Button id="block-but-2" variant="outlined" onClick={handleOpen}>
        Login
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{...style}} id="bx-2">
          <img id="cros" src={Cross} alt="cross" onClick={handleClose} />
          <div>
            {/* <Link to="/Landing">
                <img id="cross" src={Cross} />
              </Link> */}
            <div className="mydmv-govtid">
              <Button id="but1" variant="contained">
                MyDMV Id
              </Button>
              <Button id="but2" variant="contained">
                Government Id
              </Button>
            </div>
            <div className="login-with-google">
              <Button id="but3" variant="outlined">
                Login with Google
              </Button>
            </div>
            <div className="txt">
              <p id="orrr">or</p>
            </div>
            <div className="txtfields">
              <TextField
                id="txtfield1"
                varitant="outlined"
                label="Email Id"
                value={userData.email}
                name="email"
                onChange={handleChange}
              />

              <a id="hehe">----------------------</a>
              <TextField
                id="txtfield2"
                varitant="outlined"
                label="Password"
                type="password"
                value={userData.password}
                name="password"
                onChange={handleChange}
              />
            </div>
            <div className="forgotpasscreateacc">
              <div classname="forgor">
                <p>Forgot Password?</p>
              </div>
              <div className="createacc">
                <p>Don't have an Account?</p>
                <Button id="but-4" variant="Primary">
                  Sign Up!
                </Button>
              </div>
            </div>
            <InstallApp userData={userData} />
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
}
function LoginDMVModal () {
  const [open, setOpen] = React.useState (false);
  const handleOpen = () => {
    setOpen (true);
  };
  const handleClose = () => {
    setOpen (false);
  };
  const navigate = useNavigate ();

  return (
    <React.Fragment>
      <Button id="block-but-1" variant="contained" onClick={handleOpen}>
        Join Now
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{...style}} id="bx-3">
          <img id="cross" src={Cross} onClick={handleClose} />

          <div className="centrerect">
            <div className="txtfields">
              <TextField id="txtfield-1" varitant="outlined" label="MyDMV Id" />
              <a id="hehe">----------------------</a>
              <TextField
                id="txtfield-2"
                varitant="outlined"
                label="Password"
                type="password"
              />
            </div>
            <div className="checkboxxx">
              <FormControlLabel
                label="Remember Password"
                control={<Checkbox />}
              />
            </div>
            <div className="login-">
              <Button
                id="but5"
                variant="outlined"
                // onClick={event => (window.location.href = '/DMVRegistration')}
                onClick={() => navigate ('/dashboard')}
              >
                Login
              </Button>
            </div>
            <div className="txt">
              <p id="orr">or</p>
            </div>
            <div className="login-">
              <Button id="but5" variant="outlined">
                Login with MyDMV Id
              </Button>
            </div>
            <div className="createacc">
              <p>Don't have an Account?</p>
              <Button
                id="but-4"
                variant="Primary"
                onClick={() => navigate ('/DMVRegistration')}
              >
                Sign Up!
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
}
function Hero () {
  return (
    <div>
      <div className="wrapper">
        <div className="leftpart">
          <div className="logo-img">
            <img src={Logo} id="logo-img" />
          </div>
          <div className="centre-txt">
            <p id="sentence-1">Bringing the government for the people</p>
            <p id="sentence-2">To the People</p>
          </div>
          <div className="centre-blocks">
            <div className="block-1">
              <h3 id="block-h3">For Departments</h3>
              <p id="block-p">Join the community of 35+ DMVs</p>
              {/* <Button id="block-but-1" variant="contained" href="/LoginDMV">
                Join Now
              </Button> */}
              <LoginDMVModal />
            </div>
            <div className="block-2">
              <h3 id="block-h3">For Individuals</h3>
              <p id="block-p">Login to your organisation's account</p>
              {/* <Button id="block-but-2" variant="outlined" onClick={handleOpen}>
                Login
              </Button> */}
              <LoginModal />
            </div>
          </div>
        </div>
        <div className="rightpart">
          <Carousel />
        </div>
      </div>
    </div>
  );
}

export default Hero;
