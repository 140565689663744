import React from "react";
import StyleSheet from "./Landing.module.css";
import Hero from "../../components/Hero/Hero";
import LandingInfo1 from "../../components/LandingInfo1/LandingInfo1";
import LandingInfo2 from "../../components/LandingInfo2/LandingInfo2";
import LandingInfo3 from "../../components/LandingInfo3/LandingInfo3";

function Landing() {
  return (
    <div>
      <Hero/>
      <div className={StyleSheet.wrapper}>
        <LandingInfo1/>
        <LandingInfo2/>
        <LandingInfo3/>
      </div>
    </div>
  );
}
export default Landing;
