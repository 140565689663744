import React from 'react';
import './Carousel.css';
import California from './../../assets/California.png';

function Carousel () {
  const data = [
    {
      title: 'Alabama',
      para: 'Alabama Department of Revenue, Motor Vehicle Division',
    },
    {
      title: 'California',
      para: 'California Department of Motor Vehicles',
    },
    {
      title: 'Hawaii',
      para: 'Hawaii Department of Transportation, Motor Vehicle and Licensing Division',
    },
    {
      title: 'Delaware',
      para: 'Delaware Division of Motor Vehicles',
    },
    {
      title: 'Florida',
      para: 'Florida Department of Highway Safety and Motor Vehicles',
    },
    {
      title: 'Indiana',
      para: 'Indiana Bureau of Motor Vehicles ',
    },
    {
      title: 'Maine',
      para: ' Maine Bureau of Motor Vehicles',
    },
    {
      title: 'Colorado',
      para: 'Colorado Department of Revenue, Division of Motor Vehicles',
    },
    {
      title: 'Florida',
      para: 'Florida Department of Highway Safety and Motor Vehicles',
    },
  ];
  return (
    <div>
      <div className="wrapper">
        <div className="carousel">
          {data.map ((item, index) => (
            <div className="carousel__item" key={index}>
              <div className="carousel__item-head">
                <img src={California} alt="" />
              </div>
              <div className="carousel__item-body">
                <p className="title">{item.title}</p>
                <p className="para">{item.para}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Carousel;
