import React from 'react';
import Info1 from "../../assets/Info-1.png";
import StyleSheet from "./LandingInfo1.module.css";

const LandingInfo1 = () => {
  return (
      <div className={StyleSheet.Info1}>
            <div className={StyleSheet.leftco1}>
              <h3 className={StyleSheet.infohead}>What we are? What do we do?</h3>
              <p className={StyleSheet.infopara}>At Carmony Labs, we are a pioneering tech company dedicated to creating custom software solutions and harnessing data-driven insights to drive innovation across various industries. With expertise in cutting-edge technologies such as AI, blockchain, and IoT, we offer client-centric consultation and strategy to help businesses thrive in a rapidly evolving landscape. Our passionate team of professionals is committed to delivering excellence in every project, all while staying true to our vision of being a catalyst for positive change and a brighter, tech-enabled future.</p>
            </div>
            <div className={StyleSheet.rightco1}>
                <img src={Info1}/>
            </div>
        </div>
  )
}

export default LandingInfo1
