import React from 'react';
import styles from './LandingInfo3.module.css';
import Info3 from '../../assets/Info-3.png';

const LandingInfo3 = () => {
  return (
    <div className={styles.Info3}>
      <div className={styles.leftco}>
        <p className={styles.infohead}>Drop Your Queries Here!</p>
        <div className={styles.infodi}>
          <input className={styles.infoinput} type="text" />
          {/* <div className="black-icon">H</div> */}
        </div>
      </div>
      <div className={styles.rightco}>
        <img src={Info3} />
      </div>

    </div>
  );
};

export default LandingInfo3;
